import { loadRemoteCss } from "./load-remote-css";

export async function loadRemoteIndex(link: string, ...files: string[]) {
  return fetch(link)
    .then((response) => {
      // console.log("page response :: ", response);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then((html) => {
      const processed_html = html.replaceAll(`src="`, `src="${link}/`);
      const parser = new DOMParser();
      const doc = parser.parseFromString(processed_html, "text/html");
      const css = loadRemoteCss(link, "style.css", "vars.css", "styleguide.css", "globals.css");
      let el: HTMLElement = undefined;
      for (const child of doc.body.childNodes) {
        // console.log("child is ", child);
        if (child.nodeType === child.TEXT_NODE) {
          // console.log("child is text node", child);
        } else {
          el = child as any;
          break;
        }
      }
      // if (!el) {
      //   throw new Error("body of element is all of type TEXT_NODE!!");
      // }
      // const width = ref2.clientWidth - 100;
      // const el_container = el.childNodes[1] as HTMLElement;
      // const el_title = el_container.childNodes[1] as HTMLElement;
      // const el_content = el_container.childNodes[3] as HTMLElement;
      // el.style.width = width + "px";
      return Promise.resolve({
        css,
        html: doc,
        html_string: html,
        html_processed: processed_html,
        first_child: el,
      });
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
